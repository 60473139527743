import { instance } from '@/services/avik-api-gateway'
import { AxiosResponse } from 'axios'
import { DailyReportMonthlySummary } from '@/types/daily-report-monthly-summary'

export function getDailyReportMonthlySummary(
  date: string
): Promise<AxiosResponse<DailyReportMonthlySummary>> {
  return instance.get(`/v2/daily-report-month-summary/traffic`, {
    params: {
      date,
    },
  })
}
