import { createDailyReportEvent } from '@/api/daily-report/createDailyReportEvent'
import { deleteDailyReportEvent } from '@/api/daily-report/deleteDailyReportEvent'
import { getAllDailyReportDeviation } from '@/api/daily-report/getAllDailyReportDeviation'
import { getAllDailyReportEvent } from '@/api/daily-report/getAllDailyReportEvent'
import { getAllDailyReportTraffic } from '@/api/daily-report/getAllDailyReportTraffic'
import {
  getAllDailyReportTrafficV2,
  DailyReportTrafficV2Payload,
} from '@/api/daily-report/getAllDailyReportTrafficV2'
import { getAllDailyReportVehicleStatus } from '@/api/daily-report/getAllDailyReportVehicleStatus'
import { getDailyReportMonthlySummary } from '@/api/daily-report/getDailyReportMonthlySummary'
import { updateDailyReportEvent } from '@/api/daily-report/updateDailyReportEvent'
import { updateDailyReportVehicleStatus } from '@/api/daily-report/updateDailyReportVehicleStatus'
import { DailyReportTrack } from '@/types/daily-report-deviation'
import { DailyReportEvent } from '@/types/daily-report-event'
import { DailyReportMonthlySummary } from '@/types/daily-report-monthly-summary'
import { DailyReportTraffic } from '@/types/daily-report-traffic'
import { DailyReportVehicleStatus } from '@/types/daily-report-vehicle-status'
import { format } from 'date-fns'
import { computed, ref, Ref } from 'vue'

export const FILTER_TRACKS_LOCALSTORAGE_KEY = 'avik_filter_tracks'

interface LoadingState {
  traffic: {
    getAll: boolean
  }
  trafficV2: {
    getAll: boolean
  }
  vehicleStatus: {
    getAll: boolean
    update: boolean
  }
  deviation: {
    getAll: boolean
  }
  monthlySummary: {
    getAll: boolean
  }
  event: {
    getAll: boolean
    delete: boolean
    create: boolean
    update: boolean
  }
}

interface DataState {
  traffic: DailyReportTraffic[]
  vehicleStatus: ({
    _oldComment: string
  } & DailyReportVehicleStatus)[]
  deviation: DailyReportTrack[]
  event: DailyReportNewEvent[]
  trafficV2: DailyReportTrafficV2Payload
  summary: DailyReportMonthlySummary
}

interface UseDailyReport {
  getAllTraffic: (date: string) => Promise<DailyReportTraffic[]>
  getAllTrafficV2: (date: string) => Promise<DailyReportTrafficV2Payload>
  getAllVehicleStatus: (date: string) => Promise<DailyReportVehicleStatus[]>
  getAllDeviation: (date: string) => Promise<DailyReportTrack[]>
  getAllEvent: (date: string) => Promise<DailyReportEvent[]>
  getMonthlySummary: (date: string) => Promise<DailyReportMonthlySummary>
  updateVehicleStatus: (
    body: Partial<DailyReportVehicleStatus>
  ) => Promise<DailyReportVehicleStatus>
  removeEvent: (eventUuid: string) => Promise<null>
  addEvent: (body: Partial<DailyReportEvent>) => Promise<DailyReportEvent>
  updateEvent: (body: Partial<DailyReportEvent>) => Promise<DailyReportEvent>
  loading: Ref<LoadingState>
  data: Ref<DataState>
  date: Ref<string>
  isPrintMode: Ref<boolean>
  selectedFilterEventType: Ref<string[]>
  percentageOrCount: Ref<string>
  showMonthlySummary: Ref<boolean>
  selectedFilterTracks: Ref<string[]>
  expandedTrafficRows: Ref<boolean>
  setFilterTracksLocalstorage: (
    project: string,
    tracks: { label: string; visible: boolean }[]
  ) => void
  getFilterTracksLocalstorage: () => {
    [key: string]: { label: string; visible: boolean }[]
  }
}

export interface DailyReportNewEvent extends DailyReportEvent {
  _new?: boolean
}

const date = ref(format(new Date(), 'yyyy-MM-dd'))
const isPrintMode = ref(false)

const selectedFilterEventType = ref([])
const selectedFilterTracks = ref([])
const percentageOrCount = ref('percentage')
const showMonthlySummary = ref(false)
const expandedTrafficRows = ref(false)

const data = ref<DataState>({
  traffic: [],
  vehicleStatus: [],
  deviation: [],
  event: [],
  summary: {} as DailyReportMonthlySummary,
  trafficV2: {
    headers: [],
    rows: [],
  },
})

const loading = ref({
  traffic: {
    getAll: false,
  },
  monthlySummary: {
    getAll: false,
  },
  vehicleStatus: {
    getAll: false,
    update: false,
  },
  deviation: {
    getAll: false,
  },
  event: {
    getAll: false,
    delete: false,
    create: false,
    update: false,
  },
  trafficV2: {
    getAll: false,
  },
})

function parseEvent(event: DailyReportEvent) {
  return {
    ...event,
    from: event.from.substring(0, 5),
    to: event.to.substring(0, 5),
  }
}

export function useDailyReport(defaultDate?: string): UseDailyReport {
  if (defaultDate) {
    date.value = defaultDate
  }
  function getAllTraffic(date: string) {
    loading.value.traffic.getAll = true
    return new Promise<DailyReportTraffic[]>((resolve, reject) => {
      getAllDailyReportTraffic(date)
        .then(({ data: traffic }) => {
          data.value.traffic = traffic
          resolve(traffic)
        })
        .catch(reject)
        .finally(() => {
          loading.value.traffic.getAll = false
        })
    })
  }

  function getAllVehicleStatus(date: string) {
    loading.value.vehicleStatus.getAll = true
    return new Promise<DailyReportVehicleStatus[]>((resolve, reject) => {
      getAllDailyReportVehicleStatus(date)
        .then(({ data: vehicleStatus }) => {
          data.value.vehicleStatus = vehicleStatus.map((vs) => ({
            ...vs,
            _oldComment: '',
          }))
          resolve(vehicleStatus)
        })
        .catch(reject)
        .finally(() => {
          loading.value.vehicleStatus.getAll = false
        })
    })
  }

  function getAllDeviation(date: string) {
    loading.value.deviation.getAll = true
    return new Promise<DailyReportTrack[]>((resolve, reject) => {
      getAllDailyReportDeviation(date)
        .then(({ data: deviation }) => {
          data.value.deviation = deviation
          resolve(deviation)
        })
        .catch(reject)
        .finally(() => {
          loading.value.deviation.getAll = false
        })
    })
  }

  function getAllEvent(date: string) {
    loading.value.event.getAll = true
    return new Promise<DailyReportEvent[]>((resolve, reject) => {
      getAllDailyReportEvent(date)
        .then(({ data: event }) => {
          data.value.event = event.map((e) => parseEvent(e))
          resolve(event)
        })
        .catch(reject)
        .finally(() => {
          loading.value.event.getAll = false
        })
    })
  }
  function getMonthlySummary(date: string) {
    loading.value.monthlySummary.getAll = true
    return new Promise<DailyReportMonthlySummary>((resolve, reject) => {
      getDailyReportMonthlySummary(date)
        .then(({ data: summary }) => {
          data.value.summary = summary
          resolve(summary)
        })
        .catch(reject)
        .finally(() => {
          loading.value.monthlySummary.getAll = false
        })
    })
  }

  function updateVehicleStatus(body: Partial<DailyReportVehicleStatus>) {
    loading.value.vehicleStatus.update = true
    return new Promise<DailyReportVehicleStatus>((resolve, reject) => {
      updateDailyReportVehicleStatus(body)
        .then(({ data: vehicleStatus }) => {
          data.value.vehicleStatus = data.value.vehicleStatus.map(
            (vehicleStatus) => {
              if (vehicleStatus.uuid === body.uuid) {
                return {
                  ...vehicleStatus,
                  ...body,
                  _oldComment: body.comment || '',
                }
              }
              return vehicleStatus
            }
          )
          resolve(vehicleStatus)
        })
        .catch(reject)
        .finally(() => {
          loading.value.vehicleStatus.update = false
        })
    })
  }

  async function addEvent(body: Partial<DailyReportEvent>) {
    loading.value.event.create = true
    return new Promise<DailyReportEvent>((resolve, reject) => {
      createDailyReportEvent(body)
        .then(({ data: event }) => {
          data.value.event.push(parseEvent(event))
          resolve(event)
        })
        .catch(reject)
        .finally(() => {
          loading.value.event.create = false
        })
    })
  }

  async function removeEvent(uuid: string) {
    loading.value.event.delete = true

    return new Promise<null>((resolve, reject) => {
      deleteDailyReportEvent(uuid)
        .then(() => {
          data.value.event = data.value.event.filter(
            (event) => event.uuid !== uuid
          )
          resolve(null)
        })
        .catch(reject)
        .finally(() => {
          loading.value.event.delete = false
        })
    })
  }

  function updateEvent(body: Partial<DailyReportEvent>) {
    loading.value.vehicleStatus.update = true
    return new Promise<DailyReportEvent>((resolve, reject) => {
      updateDailyReportEvent(body)
        .then(({ data: event }) => {
          data.value.event = data.value.event.map((e) => {
            if (e.uuid === event.uuid) {
              return parseEvent(event)
            }
            return e
          })

          resolve(event)
        })
        .catch(reject)
        .finally(() => {
          loading.value.vehicleStatus.update = false
        })
    })
  }

  function getAllTrafficV2(date: string) {
    loading.value.trafficV2.getAll = true
    return new Promise<DailyReportTrafficV2Payload>((resolve, reject) => {
      getAllDailyReportTrafficV2(date)
        .then(({ data: traffic }) => {
          data.value.trafficV2 = traffic
          resolve(traffic)
        })
        .catch(reject)
        .finally(() => {
          loading.value.trafficV2.getAll = false
        })
    })
  }

  function getFilterTracksLocalstorage() {
    const tracks = window.localStorage.getItem(FILTER_TRACKS_LOCALSTORAGE_KEY)

    if (!tracks) return {}

    return JSON.parse(tracks)
  }

  function setFilterTracksLocalstorage(
    project: string,
    tracks: { label: string; visible: boolean }[]
  ) {
    const projectFilter = getFilterTracksLocalstorage()
    if (projectFilter && Object.values(projectFilter).length) {
      window.localStorage.setItem(
        FILTER_TRACKS_LOCALSTORAGE_KEY,
        JSON.stringify({
          ...projectFilter,
          [project]: tracks,
        })
      )
    } else {
      window.localStorage.setItem(
        FILTER_TRACKS_LOCALSTORAGE_KEY,
        JSON.stringify({
          [project]: tracks,
        })
      )
    }
  }

  return {
    isPrintMode,
    getAllTraffic,
    getAllVehicleStatus,
    getAllDeviation,
    getAllEvent,
    updateVehicleStatus,
    loading,
    data: computed(() => data.value),
    removeEvent,
    updateEvent,
    addEvent,
    date,
    selectedFilterEventType,
    selectedFilterTracks,
    percentageOrCount,
    getAllTrafficV2,
    setFilterTracksLocalstorage,
    getFilterTracksLocalstorage,
    expandedTrafficRows,
    showMonthlySummary,
    getMonthlySummary,
  }
}
